import React, { useState, useEffect } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from '@reach/router'
import { useSelector } from 'react-redux'
import * as userService from '../../services/userService'
import {
  Loader,
  UserOverviewWrapper,
  CheckInWrapper,
  CheckInButton,
  CheckedMessage,
} from './styles'
import Container from '../../components/shared/Container/Container'
import UserProfile from '../../components/UserProfile/UserProfile'

const formateDate = ({ utc }) => {
  const date = new Date(utc)
  return date.toLocaleString()
}

const UserOverview = () => {
  const [isLoading, setLoading] = useState(true)
  const [userEvents, setEvents] = useState([])
  const [userData, setUser] = useState(null)
  const [userRegistered, setUserRegistered] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    userService
      .getUserOverview(id)
      .then((result) => {
        const { user, events, registered } = result
        setEvents(events)
        setUser(user)
        setUserRegistered(registered)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [id])

  const { entity } = useSelector(({ profile }) => profile)

  const checkIn = async () => {
    const result = await userService.checkIn(id, entity.venue)
    if (result) {
      setUserRegistered(true)
    }
  }

  return (
    <Container>
      <UserOverviewWrapper>
        {isLoading ? (
          <Loader>
            <h1>Loading...</h1>
          </Loader>
        ) : (
          <>
            <CheckInWrapper>
              {userRegistered ? (
                <CheckedMessage>Registered</CheckedMessage>
              ) : (
                <CheckInButton onClick={checkIn}>Check-in</CheckInButton>
              )}
            </CheckInWrapper>
            {userData && <UserProfile user={userData} />}
            <ul>
              {userEvents &&
                userEvents.map((event) => (
                  <li key={event.id}>
                    <h3 style={{ marginBottom: '20px' }}>{event.name}</h3>
                    <p>{formateDate(event.startTime)}</p>
                  </li>
                ))}
            </ul>
          </>
        )}
      </UserOverviewWrapper>
    </Container>
  )
}

export default UserOverview
