import styled from 'styled-components'

export const UserOverviewWrapper = styled.div`
  min-height: 100vh;
`

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

export const CheckInButton = styled.button`
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  background-color: red;
  border: 2px solid #fff;
`

export const CheckedMessage = styled.span`
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: red;
  background-color: #fff;
  border: 2px solid red;
`

export const CheckInWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
  padding-top: 120px;
`
