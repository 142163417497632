/* eslint-disable no-bitwise */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { rolesPermissions } from '../../helpers/roles'

const calcRequiredPermissions = (permissionsList) =>
  permissionsList.reduce((sum, permission) => sum | permission, 0)

const calcUserPermissions = (roles) =>
  roles.reduce((sum, role) => sum | rolesPermissions[role], 0)

const PrivateRoute = ({
  component: Component,
  location,
  permissions,
  ...rest
}) => {
  const { isAuthenticated, entity } = useSelector(({ profile }) => profile)
  if (!isAuthenticated && location.pathname !== `/app/login`) {
    navigate('/app/login', { state: { from: location } })
    return null
  }

  if (permissions) {
    const isAuthorized =
      calcRequiredPermissions(permissions) & calcUserPermissions(entity.roles)

    if (!isAuthorized) {
      navigate('/app/me')
      return null
    }
  }

  return <Component {...rest} />
}

export default PrivateRoute
