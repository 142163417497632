import React from 'react'
import { useForm } from 'react-hook-form'

import {
  StyledWrapper,
  StyledForm,
  StyledInput,
  StyledLabel,
  FormField,
} from './styles'
import Container from '../shared/Container/Container'

const LoginForm = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm()

  return (
    <Container>
      <StyledWrapper>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <StyledLabel htmlFor="username">Username</StyledLabel>
            <StyledInput
              id="username"
              name="username"
              ref={register({ required: true })}
            />
          </FormField>
          <FormField>
            <StyledLabel htmlFor="password">Password</StyledLabel>
            <StyledInput
              name="password"
              id="password"
              ref={register({ required: true })}
            />
          </FormField>
          <input type="submit" />
        </StyledForm>
      </StyledWrapper>
    </Container>
  )
}

export default LoginForm
