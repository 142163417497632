/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/Layout/Layout'
import UserOverview from '../containers/UserOverview/UserOverview'
import Login from '../containers/Login/Login'
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'
import Profile from '../containers/Profile/Profile'
import { permissions } from '../helpers/roles'
import Dashboard from '../components/Dashboard/Dashboard'

const { ACCESS_READ_USER } = permissions

const App = () => (
  <Layout>
    <Router basepath="/app">
      <PrivateRoute
        path="/users/:id"
        permissions={[ACCESS_READ_USER]}
        component={UserOverview}
      />
      <PrivateRoute
        path="/dashboard"
        component={Dashboard}
        permissions={[ACCESS_READ_USER]}
      />
      <PrivateRoute path="/me" component={Profile} />
      <Login path="/login" />
    </Router>
  </Layout>
)

export default App
