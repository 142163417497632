import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUserEvents, dropUserEvents } from './actions'

const UserEvents = ({ userId }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUserEvents(userId))

    return () => {
      dispatch(dropUserEvents())
    }
  }, [userId, dispatch])

  const { isLoading, byId, allIds } = useSelector(
    ({ userEvents }) => userEvents
  )
  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        allIds &&
        allIds.length && (
          <ul>
            {allIds.map((id) => (
              <li key={id}>{`${byId[id].name} ${byId[id].startTime.utc}`}</li>
            ))}
          </ul>
        )
      )}
    </>
  )
}

export default UserEvents
