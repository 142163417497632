import React from 'react'
import { UserInfo } from './styles'

const UserProfile = ({ user }) => {
  const username = user.username || (user.telegram && user.telegram.username)
  const firstName = user.firstName || (user.telegram && user.telegram.firstName)
  const lastName = user.lastName || (user.telegram && user.telegram.lastName)
  const photoUrl = user.photoUrl || (user.telegram && user.telegram.photoUrl)

  return (
    <UserInfo>
      {photoUrl && (
        <img
          src={photoUrl}
          alt={firstName}
          style={{
            width: '300px',
            height: '300px',
            marginRight: '30px',
          }}
        />
      )}
      <div>
        <h1 style={{ marginBottom: '10px' }}>
          {`${firstName}${lastName ? ` ${lastName}` : ''}`}
        </h1>
        {username && <h3 style={{ marginTop: 0 }}>{username}</h3>}
      </div>
    </UserInfo>
  )
}

export default UserProfile
