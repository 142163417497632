import React from 'react'
import { useSelector } from 'react-redux'
import UserProfile from '../../components/UserProfile/UserProfile'
import UserEvents from '../UserEvents/UserEvents'
import Container from '../../components/shared/Container/Container'

const Profile = () => {
  const { isLoading, entity } = useSelector(({ profile }) => profile)

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <Container>
      <UserProfile user={entity} />
      <UserEvents userId={entity.id} />
    </Container>
  )
}

export default Profile
