import styled from 'styled-components'

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4em 0 2em;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`
