import React from 'react'
import { useDispatch } from 'react-redux'
import { login } from './actions'
import LoginForm from '../../components/LoginForm/LoginForm'
import useAuth from '../../hooks/useAuth'

const Login = () => {
  const dispatch = useDispatch()
  const dispatchLoginUser = (data) => dispatch(login(data))

  useAuth()

  const handleSubmit = (loginData) => {
    dispatchLoginUser(loginData)
  }
  return <LoginForm onSubmit={handleSubmit} />
}

export default Login
