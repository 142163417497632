import styled from 'styled-components'

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`

export const StyledForm = styled.form`
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;
  padding: 1em 2em;
`

export const FormField = styled.div`
  box-sizing: border-box;
  margin-bottom: 1em;
`

export const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 15px;
`

export const SubmitButton = styled.button`
  box-sizing: border-box;
  padding: 1em;
  background-color: green;
  color: #fff;
`

export const StyledLabel = styled.label`
  margin-bottom: 1em;
`
