import { useSelector } from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate, useLocation } from '@reach/router'
import { useEffect } from 'react'

const useAuth = () => {
  const isAuthenticated = useSelector(({ profile }) => profile.isAuthenticated)

  const navigate = useNavigate()
  const location = useLocation()

  const { from } = location.state || { from: { pathname: '/app/dashboard' } }

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from.pathname, { replace: true })
    }
  }, [isAuthenticated, navigate, from])
}

export default useAuth
