export const permissions = {
  ACCESS_READ_USER: 64, // 0b1000000
}

export const roles = {
  user: 'USER',
  employee: 'EMPLOYEE',
  gatekeeper: 'GATEKEEPER',
}

export const rolesPermissions = {
  GATEKEEPER: permissions.ACCESS_READ_USER,
}
